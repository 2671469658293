import DataTransferUtil from '@/utils/data-transfer.util';

export const LOG_COLUMNS = [
  {
    title: '操作类型',
    key: 'type',
    slot: 'type',
    minWidth: 100,
  },
  {
    title: '操作内容',
    key: 'content',
    minWidth: 180,
  },
  {
    title: '用户名',
    key: 'customer_nick_name',
    minWidth: 140,
  },
  {
    title: '手机号',
    key: 'mobile',
    minWidth: 130,
  },
  {
    title: '操作人',
    key: 'opt_nick_name',
    minWidth: 140,
  },
  {
    title: '操作时间',
    key: 'create_time',
    minWidth: 180,
  },
];

export const OPERATION_TYPE = {
  DISABLE: 0,
  ENABLE: 1,
  EDIT: 2,
  // CREATE: 3,
  ALL: 'all',
};

export const BASIC_OPERATION_TYPE_TEXT = {
  [OPERATION_TYPE.DISABLE]: '停用',
  [OPERATION_TYPE.ENABLE]: '启用',
  [OPERATION_TYPE.EDIT]: '修改',
  // [OPERATION_TYPE.CREATE]: '创建',
};

export const OPERATION_TYPE_TEXT = {
  ...BASIC_OPERATION_TYPE_TEXT,
  [OPERATION_TYPE.ALL]: '全部',
};

export const OPERATION_TYPE_OPTIONS = DataTransferUtil.enumTransfer2Options(OPERATION_TYPE_TEXT);
