import axios from '@/plugins/axios';
import { OPERATION_TYPE } from './constants';

class ConsumerAccountLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    type = OPERATION_TYPE.ALL,
    customerNickName = '',
    optNickName = '',
    mobile = '',
  ) {
    return axios({
      url: '/api/sys/log/customer/page',
      data: {
        page,
        limit,
        type,
        customer_nick_name: customerNickName,
        opt_nick_name: optNickName,
        mobile, // TODO: 接口缺失参数
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询消费者账户日志',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/sys/log/customer/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default ConsumerAccountLogApi;
